import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import TitlePage from './TitlePage';
import GalleryPage from './GalleryPage';
import MapPage from './MapPage';
import RSVPPage from './RSVPPage';
import AccountPage from './AccountPage';
import CommentPage from './CommentPage';
import CalendarPage from './CalendarPage';

function App() {
  const { t, i18n } = useTranslation();
  useEffect(() => {
    document.body.setAttribute('data-language', i18n.language);
    console.log('Detected Language:', i18n.language);
  }, [i18n.language]);

  const [isNavOpen, setIsNavOpen] = useState(false);

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const copyLinkToClipboard = () => {
    const link = "sb-yj-wedding-invitation.com";
    navigator.clipboard.writeText(link)
      .then(() => alert(t('copy'))) // Use the translation key for the copied message
      .catch(err => console.error("Failed to copy link: ", err));
  };
  

  const toggleMobileMenu = () => {
    setIsNavOpen(!isNavOpen);
  };

  return (
    <div className="App bg-white text-gray-800">
      {/* Navigation Bar */}
      <nav className="bg-white text-gray-800 border-b border-gray-200 p-4 fixed top-0 w-full z-50 sm:bg-white sm:backdrop-blur-lg">
        <div className="max-w-6xl mx-auto flex justify-between items-center">
          {/* Language Switcher */}
          <div className="flex space-x-2">
            <button
              onClick={() => changeLanguage('ko')}
              className="text-sm bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-l transition duration-300 ease-in-out shadow-md"
            >
              한국어
            </button>
            <button
              onClick={() => changeLanguage('en')}
              className="text-sm bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded-r transition duration-300 ease-in-out shadow-md"
            >
              English
            </button>
          </div>


          {/* Desktop Navigation */}
          <ul className="hidden sm:flex space-x-4">
            <li><a href="#home" className="hover:bg-opacity-70">{t('home')}</a></li>
            <li><a href="#gallery" className="hover:bg-opacity-70">{t('gallery')}</a></li>
            <li><a href="#map" className="hover:bg-opacity-70">{t('directions')}</a></li>
            <li><a href="#rsvp" className="hover:bg-opacity-70">{t('rsvp')}</a></li>
            <li><a href="#comment" className="hover:bg-opacity-70">{t('comments')}</a></li>
            <li><a href="#account" className="hover:bg-opacity-70">{t('account')}</a></li>
          </ul>

          {/* Mobile Navigation (Hamburger Menu) */}
          <button onClick={toggleMobileMenu} className="text-gray-800 focus:outline-none sm:hidden">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="h-6 w-6">
              <path fillRule="evenodd" d="M3 5h14a1 1 0 010 2H3a1 1 0 110-2zm0 6h14a1 1 0 010 2H3a1 1 0 110-2zm0 6h14a1 1 0 010-2H3a1 1 0 110-2z" clipRule="evenodd" />
            </svg>
          </button>
        </div>

        {/* Mobile Menu (Hidden on Desktop) */}
        <ul className={`sm:hidden ${isNavOpen ? 'block' : 'hidden'} mt-4`}>
          <li><a href="#home" className="block p-2" onClick={toggleMobileMenu}>{t('home')}</a></li>
          <li><a href="#gallery" className="block p-2" onClick={toggleMobileMenu}>{t('gallery')}</a></li>
          <li><a href="#map" className="block p-2" onClick={toggleMobileMenu}>{t('directions')}</a></li>
          <li><a href="#rsvp" className="block p-2" onClick={toggleMobileMenu}>{t('rsvp')}</a></li>
          <li><a href="#comment" className="block p-2" onClick={toggleMobileMenu}>{t('comments')}</a></li>
          <li><a href="#account" className="block p-2" onClick={toggleMobileMenu}>{t('account')}</a></li>
        </ul>
      </nav>

      {/* Main Content */}
      <main className="mt-20">
        <div id="home" className="section">
          <TitlePage />
        </div>
        <div id="calendar" className="section">
          <CalendarPage />
        </div>
        <div id="gallery" className="section">
          <GalleryPage />
        </div>
        <div id="map" className="section">
          <MapPage />
        </div>
        <div id="rsvp" className="section">
          <RSVPPage />
        </div>
        <div id="comment" className="section">
          <CommentPage />
        </div>
        <div id="account" className="section">
          <AccountPage />
        </div>
      </main>

    {/* Link Copy and Footer */}
    <div className="flex justify-center items-center my-4 h-10">
      <button onClick={copyLinkToClipboard} className="flex items-center bg-white text-black px-4 py-2 rounded-full hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-300">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" className="inline-block w-6 h-6 mr-1">
          <path fillRule="evenodd" d="M12.586 4.586a2 2 0 112.828 2.828l-3 3a2 2 0 01-2.828 0 1 1 0 00-1.414 1.414 4 4 0 005.656 0l3-3a4 4 0 00-5.656-5.656l-1.5 1.5a1 1 0 101.414 1.414l1.5-1.5zm-5 5a2 2 0 012.828 0 1 1 0 101.414-1.414 4 4 0 00-5.656 0l-3 3a4 4 0 105.656 5.656l1.5-1.5a1 1 0 10-1.414-1.414l-1.5 1.5a2 2 0 11-2.828-2.828l3-3z" clipRule="evenodd" />
        </svg>
        {t('copyLink')}
      </button>
    </div>


      <footer className="bg-gray-800 text-white text-center py-4">
        <div className="max-w-6xl mx-auto px-4">
          <p className="text-sm">&copy; {(new Date().getFullYear())} YJ. All Rights Reserved.</p>
          {/* You can add additional footer content here, such as links or social icons */}
        </div>
      </footer>
    </div>
  );
}

export default App;

