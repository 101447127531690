import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

// Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyD0qDIAANYj80zNIdkrbVQUw3nH0EaN7xs",
    authDomain: "mobile-wedding-yjsb.firebaseapp.com",
    projectId: "mobile-wedding-yjsb",
    storageBucket: "mobile-wedding-yjsb.appspot.com",
    messagingSenderId: "439019030299",
    appId: "1:439019030299:web:2ae442e4dc4d7ccd34f5be"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);

export { db };