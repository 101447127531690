import React, { useState } from 'react';
// import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import Modal from './Modal';
import { useTranslation } from 'react-i18next';
// import Modal from './Modal'; // Import a Modal component you might have or create a new one

// Existing photos
// import photo1 from './images/Gallery/1.JPG';
// import photo2 from './images/Gallery/2.JPG';
// import photo3 from './images/Gallery/3.JPG';
// import photo4 from './images/Gallery/4.JPG';
// import photo5 from './images/Gallery/5.JPG';
// import photo6 from './images/Gallery/6.JPG';
// import photo7 from './images/Gallery/7.JPG';

// New photos
import photo8 from './images/Gallery/8.jpg';
import photo9 from './images/Gallery/9.jpg';
import photo10 from './images/Gallery/10.jpg';
import photo11 from './images/Gallery/11.jpg';
import photo12 from './images/Gallery/12.jpg';
import photo13 from './images/Gallery/13.jpg';
import photo14 from './images/Gallery/14.jpg';
import photo15 from './images/Gallery/15.jpg';
import photo16 from './images/Gallery/16.jpg';
import photo17 from './images/Gallery/17.jpg';
import photo18 from './images/Gallery/18.jpg';
import photo19 from './images/Gallery/19.jpg';
import photo20 from './images/Gallery/20.jpg';
import photo21 from './images/Gallery/21.jpg';
import photo22 from './images/Gallery/22.jpg';
import photo23 from './images/Gallery/23.jpg';
import photo24 from './images/Gallery/24.jpg';
import photo25 from './images/Gallery/25.jpg';
import photo26 from './images/Gallery/26.jpg';
import photo27 from './images/Gallery/27.jpg';

// import flowerDeco from './images/flower-deco.png';

function GalleryPage() {
  const { t } = useTranslation();
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);

  
  // Add the new photos to the array
  const [photos] = useState([
        // photo1, photo2, photo3, photo4, photo5, photo6, photo7,
    photo9, photo10, photo11, photo12, photo13, photo14, photo15, 
    photo16, photo17, photo18, photo19, photo20, photo21, photo22, photo23, 
    photo24, photo25, photo26, photo27, photo8  ]);

  const [showModal, setShowModal] = useState(false);
  // const [additionalPhotos] = useState(photos.slice(9)); // Photos after the first 9

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <div className="min-h-screen bg-gray-50 py-8">
      <div className="text-center">
        <h1 className="text-6xl font-script text-gray-900 mb-4">Photos</h1>
        <div className="inline-block w-10 h-1 bg-gray-900 mb-8"></div>
      </div>

      <div className="max-w-5xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="grid grid-cols-3 gap-4">
          {photos.slice(0, 9).map((photo, index) => (
            <div key={index} className="aspect-square bg-white shadow overflow-hidden rounded-lg cursor-pointer">
              <img
                src={photo}
                alt={`Wedding moment ${index}`}
                className="w-full h-full object-cover"
                onClick={() => openModal(index)}
              />
            </div>
          ))}
        </div>

        <div className="text-center mt-8">
          <button
            onClick={() => openModal(0)} // Open the modal starting with the first image of the additional set if needed
            className="text-white bg-gray-900 hover:bg-gray-700 font-medium py-2 px-4 rounded"
          >
            {t('galleryPage.plus')}
          </button>
        </div>
      </div>

      {showModal && (
        <Modal onClose={closeModal} photos={photos} initialIndex={selectedImageIndex} />
      )}
    </div>
  );
}

export default GalleryPage;