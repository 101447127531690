import React, { useState, useEffect } from 'react';
import flowerDeco from './images/flower-deco.png';
import { FaHeart } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

function CalendarPage() {
  const { t } = useTranslation(); // i18n is now correctly destructured from useTranslation

  const daysOfWeek = t('calendarPage.daysOfWeek', { returnObjects: true });

  const [timeLeft, setTimeLeft] = useState({
    days: '',
    hours: '',
    minutes: '',
    seconds: '',
  });

  useEffect(() => {
    const targetDate = new Date('2024-04-28T13:00:00Z');
    const interval = setInterval(() => {
      const now = new Date();
      const difference = targetDate - now;
      setTimeLeft({
        days: Math.max(0, Math.floor(difference / (1000 * 60 * 60 * 24))).toString().padStart(2, '0'),
        hours: Math.max(0, Math.floor((difference / (1000 * 60 * 60)) % 24)).toString().padStart(2, '0'),
        minutes: Math.max(0, Math.floor((difference / 1000 / 60) % 60)).toString().padStart(2, '0'),
        seconds: Math.max(0, Math.floor((difference / 1000) % 60)).toString().padStart(2, '0'),
      });

      if (difference < 0) {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  

  if (!Array.isArray(daysOfWeek)) {
    console.error('daysOfWeek is not an array');
    return <div>Error loading translations</div>;
  }

  const generateCalendarGrid = () => {
    let grid = [];
    const totalDays = 30;
    const firstDayOfWeek = 1;

    for (let i = 0; i < firstDayOfWeek; i++) {
      grid.push(<div key={`pad-${i}`} className="h-10"></div>);
    }

    for (let day = 1; day <= totalDays; day++) {
      grid.push(
        <div key={day} className={`relative h-10 flex items-center justify-center ${day === 28 ? 'text-white' : 'text-gray-700'} rounded-full`}>
          {day === 28 && (
            <FaHeart className="absolute inset-0 m-auto w-8 h-8 text-red-500 opacity-60" />
          )}
          <span className="relative z-10">{day}</span>
        </div>
      );
    }
    
    return grid;
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-200 p-4">

      <img src={flowerDeco} alt={t('calendarPage.flowerDecoration')} className="w-32 md:w-48 mb-6" />
      <h1 className="text-4xl font-bold mb-4">{t('calendarPage.weddingDate')}</h1>
      <h2 className="mb-6 text-lg">{t('calendarPage.weddingTime')}</h2>

      <div className="w-full max-w-xl mx-auto mb-6">
        <div className="grid grid-cols-7 gap-1">
          {daysOfWeek.map((day, index) => (
            <div key={index} className="text-center font-bold text-gray-600">
              {day}
            </div>
          ))}
          {generateCalendarGrid()}
        </div>
      </div>

      <div className="flex space-x-4 mb-6">
        <CountdownItem label={t('calendarPage.days')} time={timeLeft.days} />
        <CountdownItem label={t('calendarPage.hours')} time={timeLeft.hours} />
        <CountdownItem label={t('calendarPage.minutes')} time={timeLeft.minutes} />
        <CountdownItem label={t('calendarPage.seconds')} time={timeLeft.seconds} />
      </div>

      <p className="text-md font-light text-center">{t('calendarPage.countdownMessage', { days: timeLeft.days })}</p>
    </div>
  );
}

function CountdownItem({ label, time }) {
  return (
    <div className="flex flex-col items-center justify-center bg-gray-200 p-4 rounded-lg">
      <span className="text-2xl font-semibold">{time}</span>
      <span className="text-xs uppercase">{label}</span>
    </div>
  );
}

export default CalendarPage;