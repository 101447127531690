import React, { useState } from 'react';
import flowerDeco from './images/flower-deco.png';
import { useTranslation } from 'react-i18next';

function AccountPage() {
  const { t } = useTranslation();
  const accountPageData = t('accountPage', { returnObjects: true });
  const [showBrideDetails, setShowBrideDetails] = useState(false);
  const [showGroomDetails, setShowGroomDetails] = useState(false);

  const copyToClipboard = (accountNumber) => {
    navigator.clipboard.writeText(accountNumber.replace(/-/g, ''))
      .then(() => {
        alert(t('accountPage.copy')); // Ensure this key exists
      })
      .catch(err => {
        console.error("Clipboard copy failed:", err);
        alert(t('accountPage.copyFailedAlert')); // Ensure this key exists
      });
  };

  const ChevronIcon = ({ isOpen }) => (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className={`w-6 h-6 transform transition duration-300 ${isOpen ? 'rotate-180' : ''}`}>
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
    </svg>
  );

  const AccountSection = ({ account }) => {
    // Check if account is defined before attempting to access its properties
    if (!account) {
      console.warn('Account data is undefined.');
      return null; // Return null or some placeholder if the account data is not available
    }

    return (
      <div className="flex justify-between items-center mb-4 p-4 bg-gray-100 rounded-lg">
        <div>
          <div className="text-sm text-gray-600">{account.bank} | {account.number}</div>
          <div className="text-sm font-medium text-gray-500">{account.name} <span className="text-gray-700">({account.role})</span></div>
        </div>
        <button onClick={() => copyToClipboard(account.number)} className="text-white bg-blue-500 hover:bg-blue-600 font-medium rounded-lg text-sm px-4 py-2 text-center mr-2 md:mr-0">
          복사
        </button>
      </div>
    );
  };

  const AccountDetails = ({ label, accounts }) => (
    <div className="viewbox bg-white rounded-lg shadow-lg mb-6 w-full md:w-1/2 lg:w-2/3">
      <button 
        onClick={() => label === accountPageData.brideSideAccount ? setShowBrideDetails(!showBrideDetails) : setShowGroomDetails(!showGroomDetails)} 
        className="w-full flex justify-between items-center cursor-pointer p-4 border-b border-gray-200"
      >
        <p className="text-xl font-semibold text-gray-800">{label}</p>
        <ChevronIcon isOpen={label === accountPageData.brideSideAccount ? showBrideDetails : showGroomDetails} />
      </button>
      {(label === accountPageData.brideSideAccount && showBrideDetails) || (label === accountPageData.groomSideAccount && showGroomDetails) ? (
        <div className="pt-4 pb-2 px-4">
          {accounts.map((account, index) => <AccountSection key={index} account={account} />)}
        </div>
      ) : null}
    </div>
  );

   return (
    <div className="flex flex-col items-center justify-start pt-8 pb-8 bg-gray-50">
      <img src={flowerDeco} alt={accountPageData.flowerDecoration} className="w-32 md:w-48 mb-8" /> {/* Ensure 'flowerDecoration' exists */}
      <h1 className="text-3xl font-semibold text-gray-800 mb-6">{accountPageData.pageTitle}</h1> {/* Ensure 'pageTitle' exists */}

      <AccountDetails
        label={accountPageData.brideSideAccount} // Ensure 'brideSideAccount' exists
        accounts={[accountPageData.accountInfo.bride_dad, accountPageData.accountInfo.bride]} // Ensure these keys exist
      />

      <AccountDetails
        label={accountPageData.groomSideAccount} // Ensure 'groomSideAccount' exists
        accounts={[accountPageData.accountInfo.groom]} // Ensure these keys exist
      />
    </div>
  );
}

export default AccountPage;