import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import weddingPhoto from './images/wedding-photo-update.JPG'; // 이미지 경로
import flowerDeco from './images/flower-deco.png'; // 꽃 데코 이미지 경로 추가
import videoThumbnail from './images/photo_video_thumbnail.png';

function TitlePage() {
  const { t } = useTranslation();
  const [showVideo, setShowVideo] = useState(false);

  const handleVideoClick = () => {
    // Here you can implement opening the video in a modal
    // For demonstration, it simply toggles the video display state
    setShowVideo(true);
  };

  return (
    <div className="flex flex-col items-center justify-center p-4 bg-white text-gray-800 md:pt-16">
      
      {/* Use the t function to translate text */}
      <div className="text-6xl font-extrabold my-4 text-gray-900 font-script">
        {t('titlePage.date')}
      </div>
      <div className="text-3xl my-2 font-semibold text-gray-800 font-noto">
        {t('titlePage.coupleNames')}
      </div>
      <div className="text-sm uppercase my-2 tracking-widest ">
        {t('titlePage.saveTheDate')}
      </div>
      <img src={weddingPhoto} alt="Couple illustration" className="w-full max-w-xs my-4 shadow-md rounded-full" />
      <div className="text-lg my-2 font-medium text-gray-700">
        {t('titlePage.eventDate')}
      </div>
      <div className="text-lg my-2 font-medium text-gray-700 text-center">
        {t('titlePage.venue')}
      </div>
      <img src={flowerDeco} alt="Flower Decoration" className="w-40 my-4" />
      <div className="text-xl font-bold my-4 uppercase italic text-gray-900">
        {t('titlePage.invitation')}
      </div>
      <div
        className="text-center text-md px-4 my-2 font-light text-gray-600"
        dangerouslySetInnerHTML={{ __html: t('titlePage.invitationText') }}
      ></div>
      {/* 고급스러운 느낌의 카드 스타일 */}
      <div className="mt-8 p-6 border border-gray-500 shadow-xl rounded-2xl bg-white mb-8">
        <div className="grid grid-cols-1 gap-4 text-center">
          <div className="px-4 py-2">
            <p className="font-semibold text-lg text-gray-800">{t('titlePage.groomParents')}</p>
            <p className="text-md text-gray-600">{t('titlePage.groomName')}</p>
          </div>
          <div className="px-4 py-2">
            <p className="font-semibold text-lg text-gray-800">{t('titlePage.brideParents')}</p>
            <p className="text-md text-gray-600">{t('titlePage.brideName')}</p>
          </div>
        </div>
      </div>


      {/* Enhanced video player with updated margins */}
      <div className="w-full max-w-4xl mx-auto mb-12">
        <div className="relative shadow-xl rounded-lg overflow-hidden" style={{ paddingTop: '56.25%' }}> {/* This maintains a 16:9 aspect ratio */}
          {showVideo ? (
            <iframe
              width="560"
              height="315"
              src="https://www.youtube.com/embed/eKxh7QM5pqw?autoplay=1"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
              style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%' }}
            ></iframe>
          ) : (
            <div className="absolute top-0 left-0 w-full h-full flex justify-center items-center cursor-pointer" onClick={handleVideoClick}>
              <img src={videoThumbnail} alt="Play video" className="w-full h-full object-cover" />
              <div className="absolute">
                <div className="p-4 rounded-full bg-red-600 bg-opacity-75">
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-16 w-16 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>


    </div>
  );
}

export default TitlePage;
