import React, { useState, useEffect } from 'react';

function Modal({ onClose, photos, initialIndex = 0 }) {
  const [currentIndex, setCurrentIndex] = useState(initialIndex);

  useEffect(() => {
    setCurrentIndex(initialIndex);
  }, [initialIndex]);

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % photos.length);
  };

  const handlePrevious = () => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + photos.length) % photos.length);
  };

  if (!Array.isArray(photos) || photos.length === 0) {
    console.error('Photos prop is not an array or is empty', photos);
    return null;
  }

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-5 bg-black bg-opacity-80 transition-opacity ease-in-out duration-500">
      <div className="relative w-full max-w-4xl mx-auto bg-white p-5 rounded-lg shadow-xl overflow-hidden transition-transform transform hover:scale-105 duration-300">
        <div className="flex justify-between items-center">
          <button onClick={handlePrevious} className="text-gray-700 hover:text-gray-900 transition-colors duration-300 ease-in-out p-2 rounded-full bg-white hover:shadow-lg">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" />
            </svg>
          </button>
          <div className="max-h-[80vh] flex justify-center items-center p-4">
            <img src={photos[currentIndex]} alt={`Slide ${currentIndex + 1}`} className="max-h-full object-contain shadow-lg transition-all ease-in-out duration-300" />
          </div>
          <button onClick={handleNext} className="text-gray-700 hover:text-gray-900 transition-colors duration-300 ease-in-out p-2 rounded-full bg-white hover:shadow-lg">
            <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
        <button onClick={onClose} className="absolute top-5 right-5 p-2 text-white bg-red-500 hover:bg-red-600 focus:outline-none rounded-full transition-colors duration-200 ease-in-out">
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
}

export default Modal;
