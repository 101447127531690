import React, { useState } from 'react';
import flowerDeco from './images/flower-deco.png';
import { db } from './firebase';
import { doc, setDoc, serverTimestamp } from 'firebase/firestore';
import { useTranslation } from 'react-i18next';

function RSVPPage() {
  const { t } = useTranslation();
  const [side, setSide] = useState('');
  const [name, setName] = useState('');
  const [numAttendees, setNumAttendees] = useState(1);
  const [mealPreference, setMealPreference] = useState('');

  const formatDate = (date) => {
    const formattedDate = new Date(date).toISOString().split('T')[0];
    return formattedDate;
  };


  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check if all fields are filled out
    if (!side || !name || !numAttendees || !mealPreference) {
      alert(t('rsvpPage.incompleteFormMessage'));
      return;
    }

    // Check if the number of attendees is within the allowed range
    if (numAttendees < 1 || numAttendees > 10) {
      alert(t('rsvpPage.invalidNumAttendeesMessage'));
      return;
    }

    try {
      const timestamp = new Date(); // Get current timestamp as Date object
      const formattedTimestamp = formatDate(timestamp);
  
      // Construct the document ID with side, name, and date
      const docId = `${side.toLowerCase()}_${name}_${formattedTimestamp.replace(/[.,/#$%^&*;:{}=\-_`~()]/g, '').replace(/ /g, '_')}`;
  

      const docRef = doc(db, 'RSVPs', docId); // Use the combined ID as the document ID
      await setDoc(docRef, {
        side: side,
        name: name,
        numAttendees: numAttendees,
        mealPreference: mealPreference,
        timestamp: serverTimestamp()
      });


      alert(t('rsvpPage.successMessage'));
      setSide('');
      setName('');
      setNumAttendees(1);
      setMealPreference('');
    } catch (error) {
      alert(error.message);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-gray-50 to-gray-100 py-8 px-4">
      <img src={flowerDeco} alt={t('rsvpPage.flowerDecoration')} className="w-32 mb-8" />

      <h2 className="text-xl font-bold my-4 uppercase text-center italic text-gray-900">
        {t('rsvpPage.title')}
      </h2>

      <div className="w-full max-w-md bg-white shadow-xl rounded-lg overflow-hidden my-5">
        <form onSubmit={handleSubmit} className="p-6 space-y-6">
          <div>
            <p className="text-lg font-medium text-gray-700 mb-3">{t('rsvpPage.sideLabel')}</p>
            <div className="flex justify-around">
              <label className="flex items-center">
                <input 
                  type="radio" 
                  className="form-radio h-5 w-5 text-indigo-500" 
                  value="Bride's Side" 
                  checked={side === "Bride's Side"} 
                  onChange={() => setSide("Bride's Side")}
                />
                <span className="ml-2 text-gray-700">{t('rsvpPage.sideOptionBride')}</span>
              </label>
              <label className="flex items-center">
                <input 
                  type="radio" 
                  className="form-radio h-5 w-5 text-pink-500" 
                  value="Groom's Side" 
                  checked={side === "Groom's Side"} 
                  onChange={() => setSide("Groom's Side")}
                />
                <span className="ml-2 text-gray-700">{t('rsvpPage.sideOptionGroom')}</span>
              </label>
            </div>
          </div>

          <div>
            <label htmlFor="name" className="block text-lg font-medium text-gray-700 mb-1">
              {t('rsvpPage.nameLabel')}
            </label>
            <input 
              id="name"
              type="text" 
              className="block w-full border border-gray-300 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
              value={name}
              onChange={(e) => setName(e.target.value)}
              placeholder={t('rsvpPage.namePlaceholder')}
            />
          </div>

          <div>
          <label htmlFor="numAttendees" className="block text-lg font-medium text-gray-700 mb-1">
            {t('rsvpPage.numAttendeesLabel')}
          </label>
          <select
            id="numAttendees"
            className="block w-full border border-gray-300 rounded-md px-3 py-2 shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-indigo-500"
            value={numAttendees}
            onChange={(e) => setNumAttendees(parseInt(e.target.value, 10))}
          >
            {[...Array(10).keys()].map((number) => (
              <option key={number + 1} value={number + 1}>
                {number + 1}
              </option>
            ))}
          </select>
        </div>

          <div>
            <p className="text-lg font-medium text-gray-700 mb-3">{t('rsvpPage.mealPreference')}</p>
            <div className="flex justify-around">
              <label className="flex items-center">
                <input 
                  type="radio" 
                  className="form-radio h-5 w-5 text-green-500" 
                  value="Yes" 
                  checked={mealPreference === 'Yes'} 
                  onChange={() => setMealPreference('Yes')}
                />
                <span className="ml-2 text-gray-700">{t('rsvpPage.mealOptionYes')}</span>
              </label>
              <label className="flex items-center">
                <input 
                  type="radio" 
                  className="form-radio h-5 w-5 text-red-500" 
                  value="No" 
                  checked={mealPreference === 'No'} 
                  onChange={() => setMealPreference('No')}
                />
                <span className="ml-2 text-gray-700">{t('rsvpPage.mealOptionNo')}</span>
              </label>
              <label className="flex items-center">
                <input 
                  type="radio" 
                  className="form-radio h-5 w-5 text-yellow-500" 
                  value="Pending" 
                  checked={mealPreference === 'Pending'} 
                  onChange={() => setMealPreference('Pending')}
                />
                <span className="ml-2 text-gray-700">{t('rsvpPage.mealOptionPending')}</span>
              </label>
            </div>
          </div>

          <button
            type="submit"
            className="w-full bg-purple-600 hover:bg-purple-700 text-white font-bold py-3 px-4 rounded-md transition duration-200 ease-in-out shadow-md"
          >
            {t('rsvpPage.submitButtonText')}
          </button>
        </form>
      </div>
    </div>
  );
}

export default RSVPPage;
