import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { db } from './firebase'; // Ensure this matches your Firebase configuration
import { doc, collection, addDoc, query, onSnapshot, orderBy, updateDoc, deleteDoc } from 'firebase/firestore';
import flowerDeco from './images/flower-deco.png'; // Adjust this path to your image location

function CommentPage() {
  const { t } = useTranslation();
  const [comments, setComments] = useState([]);
  const [newComment, setNewComment] = useState('');
  const [authorName, setAuthorName] = useState('');
  const [commentPassword, setCommentPassword] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const commentsPerPage = 5;

  useEffect(() => {
    const q = query(collection(db, "comments"), orderBy("timestamp", "desc"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const fetchedComments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate().toLocaleString() || t('commentPage.noDate'),
      }));
      setComments(fetchedComments);
    });

    return () => unsubscribe();
  }, [t]);

  const handleCommentSubmit = async (e) => {
    e.preventDefault();
    if (!authorName.trim() || !newComment.trim() || !commentPassword.trim()) {
      alert(t('commentPage.fillAllFieldsMessage'));
      return;
    }

    try {
      await addDoc(collection(db, "comments"), {
        author: authorName,
        text: newComment,
        password: commentPassword, // This stores the password in plain text, which is not recommended for production apps
        timestamp: new Date(),
      });

      setNewComment('');
      setAuthorName('');
      setCommentPassword('');
    } catch (error) {
      console.error("Error adding comment: ", error);
      alert(t('commentPage.errorAddingComment'));
    }
  };

  // Pagination logic
  const indexOfLastComment = currentPage * commentsPerPage;
  const indexOfFirstComment = indexOfLastComment - commentsPerPage;
  const currentComments = comments.slice(indexOfFirstComment, indexOfLastComment);
  const totalPages = Math.ceil(comments.length / commentsPerPage);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  return (
    <div className="space-y-6">
      <img src={flowerDeco} alt={t('commentPage.flowerDecoration')} className="mx-auto my-4" style={{ maxWidth: '150px' }} />
      <div className="text-center">
        <h1 className="text-3xl font-bold">{t('commentPage.title')}</h1>
      </div>
      <div className="max-w-2xl mx-auto">
        <form onSubmit={handleCommentSubmit} className="space-y-4 bg-white p-6 rounded-lg shadow">
          <div>
            <label htmlFor="authorName" className="block text-sm font-semibold mb-2">{t('commentPage.nameLabel')}</label>
            <input
              id="authorName"
              type="text"
              value={authorName}
              onChange={(e) => setAuthorName(e.target.value)}
              className="w-full border-gray-300 rounded-md shadow-sm"
              placeholder={t('commentPage.namePlaceholder')}
              required
            />
          </div>
          <div>
            <label htmlFor="newComment" className="block text-sm font-semibold mb-2">{t('commentPage.commentLabel')}</label>
            <textarea
              id="newComment"
              value={newComment}
              onChange={(e) => setNewComment(e.target.value)}
              className="w-full border-gray-300 rounded-md shadow-sm"
              placeholder={t('commentPage.commentPlaceholder')}
              rows="4"
              required
            />
          </div>
          <div>
            <label htmlFor="commentPassword" className="block text-sm font-semibold mb-2">{t('commentPage.setPassword')}</label>
            <input
              id="commentPassword"
              type="password"
              value={commentPassword}
              onChange={(e) => setCommentPassword(e.target.value)}
              className="w-full border-gray-300 rounded-md shadow-sm"
              placeholder={t('commentPage.passwordPlaceholder')}
              required
            />
          </div>
          <button type="submit" className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
            {t('commentPage.submitButton')}
          </button>
        </form>

        {/* Render each comment */}
        <div className="space-y-4 mt-6">
          {currentComments.map((comment) => (
            <Comment key={comment.id} comment={comment} db={db} />
          ))}
        </div>
        {/* Pagination controls */}
        <div className="flex justify-center mt-4">
          {Array.from({ length: totalPages }, (_, i) => (
            <button
              key={i + 1}
              onClick={() => paginate(i + 1)}
              className={`px-3 py-1 border ${currentPage === i + 1 ? 'bg-blue-500 text-white' : 'bg-white'} mx-1`}
            >
              {i + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
}
function Comment({ comment, db }) {
  const { t } = useTranslation();
  const [isEditing, setIsEditing] = useState(false);
  const [actionType, setActionType] = useState(''); // Determines 'edit' or 'delete' action
  const [editedText, setEditedText] = useState(comment.text);
  const [editPassword, setEditPassword] = useState('');

  // Function to start the edit process
  const initiateEdit = () => {
    setActionType('edit');
    setIsEditing(true); // Show input fields for editing
  };

  // Function to start the delete process
  const initiateDelete = () => {
    setActionType('delete');
    // Instead of immediately showing input fields, ask for confirmation first
    if (window.confirm(t('commentPage.confirmDelete'))) {
      setIsEditing(true); // This will now conditionally show a password input
    }
  };

  const cancelAction = () => {
    setIsEditing(false);
    setEditPassword('');
    setActionType('');
    // Reset the edited text only if canceling an edit, not a delete action
    if (actionType === 'edit') {
      setEditedText(comment.text);
    }
  };

  // Function to proceed with the current action, based on actionType
  const proceedWithAction = async () => {
    if (editPassword !== comment.password) {
      alert(t('commentPage.incorrectPassword'));
      return;
    }
    
    if (actionType === 'edit') {
      // Proceed to save the edited comment
      try {
        await updateDoc(doc(db, "comments", comment.id), { text: editedText });
        alert(t('commentPage.commentUpdated'));
      } catch (error) {
        console.error("Error updating comment: ", error);
        alert(t('commentPage.errorUpdatingComment'));
      }
    } else if (actionType === 'delete') {
      // Proceed to delete the comment
      try {
        await deleteDoc(doc(db, "comments", comment.id));
        alert(t('commentPage.commentDeleted'));
      } catch (error) {
        console.error("Error deleting comment: ", error);
        alert(t('commentPage.errorDeletingComment'));
      }
    }

    cancelAction(); // Reset state after the action
  };
  // Flex container for comment content to ensure alignment
  const commentContentStyle = {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start', // Aligns items to the top of the container
    marginBottom: '8px',
  };

  // Ensuring enough space for buttons and text
  const commentTextStyle = {
    flex: 1,
    marginRight: '12px', // Space between text and buttons
    wordBreak: 'break-word', // Ensures text breaks to prevent overflow
  };

  // Fixed width for the buttons container to prevent layout shifts
  const buttonsContainerStyle = {
    minWidth: '75px', // Minimum width for buttons container
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
  };


  // Button styles
  const buttonStyle = {
    cursor: 'pointer',
    marginBottom: '4px', // space between buttons
    fontSize: '12px', // smaller font size
    padding: '5px 10px', // smaller padding
  };


return (
  <div className="bg-gray-100 rounded-lg p-4 shadow space-y-2 relative">
    {isEditing ? (
      <>
        {actionType === 'edit' && (
          <textarea
            value={editedText}
            onChange={(e) => setEditedText(e.target.value)}
            className="w-full p-2 rounded border mb-2"
          />
        )}
        <input
          type="password"
          value={editPassword}
          onChange={(e) => setEditPassword(e.target.value)}
          placeholder={t('commentPage.passwordPlaceholder')}
          className="w-full p-2 rounded border"
        />
        <div className="flex justify-between items-center mt-2">
          <button onClick={cancelAction} className="text-gray-600 hover:text-gray-800 transition-colors duration-150">
            {t('commentPage.cancel')}
          </button>
          <button onClick={proceedWithAction} className={`ml-auto ${actionType === 'edit' ? 'bg-blue-500' : 'bg-red-500'} hover:${actionType === 'edit' ? 'bg-blue-700' : 'bg-red-700'} text-white font-bold py-1 px-4 rounded transition-colors duration-150`}>
            {actionType === 'edit' ? t('commentPage.save') : t('commentPage.delete')}
          </button>
        </div>
      </>
    ) : (
      <div style={commentContentStyle}>
        <div style={commentTextStyle}>
          <div className="font-semibold text-md mb-1">{comment.author}</div>
            <p className="text-gray-600 text-sm">{comment.text}</p>
            <div className="text-gray-400 text-xs">{comment.timestamp}</div>
          </div>
            
          <div style={buttonsContainerStyle}>
            <button
              onClick={initiateEdit}
              style={{ ...buttonStyle, backgroundColor: '#eff6ff', color: '#3b82f6' }} // A lighter shade for the edit button
            >
              {t('commentPage.edit')}
            </button>
            <button
              onClick={initiateDelete}
              style={{ ...buttonStyle, backgroundColor: '#fee2e2', color: '#ef4444' }} // A red shade for the delete button
            >
              {t('commentPage.delete')}
            </button>
          </div>
        </div>
      )}
    </div>
  );
}


export default CommentPage;