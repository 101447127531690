import React from 'react';
import flowerDeco from './images/flower-deco.png';
import kakaoLogo from './images/kakao.png';
import naverLogo from './images/naver.png';
import { PhoneIcon, GlobeAltIcon } from '@heroicons/react/24/solid';
import { useTranslation } from 'react-i18next';

function MapPage() {
  const { t } = useTranslation();

  const mapHtmlContent = {
    __html: `<div style="font:normal normal 400 12px/normal dotum, sans-serif; width:640px; height:392px; color:#333; position:relative">
               <div style="height: 360px;">
                 <a href="https://map.kakao.com/?urlX=503740.0&amp;urlY=686156.0&amp;itemId=15194456&amp;q=힐스코트&amp;srcid=15194456&amp;map_type=TYPE_MAP&amp;from=roughmap" target="_blank">
                   <img class="map" src="http://t1.daumcdn.net/roughmap/imgmap/15d6ca6f990804f05597ec1698e25586bbb30d007b4e2d511bf3581c19819035" width="638px" height="358px" style="border:1px solid #ccc;">
                 </a>
               </div>
             </div>`
  };

  const TransportSection = ({ icon, title, details }) => {
    const titleClassName = title === 'expressBusTitle' || title === 'publicTransportTitle' || title === 'ebusTitle'
      ? 'text-xl font-semibold mb-2 primary-color'
      : 'text-xl font-semibold mb-2';

    return (
      <div className="flex items-center mb-4">
        <span className="text-4xl mr-4">{icon}</span>
        <div>
          <h3 className={titleClassName}>{title}:</h3>
          {details.map((detail, index) => (
            <p key={index} className="text-lg text-gray-600">{detail}</p>
          ))}
        </div>
      </div>
    );
  };

  const MapButton = ({ icon, link }) => (
    <a href={link} target="_blank" rel="noopener noreferrer" className="flex flex-col items-center justify-center">
      <img src={icon} alt="Map Icon" className="w-12 h-12" />
    </a>
  );

  return (
    <div className="flex flex-col items-center bg-gray-50  shadow-2xl overflow-hidden p-8">
      <img src={flowerDeco} alt={t('mapPage.flowerDecoration')} className="w-32 mb-8" />
      <div className="text-xl font-bold my-4 uppercase text-center italic text-gray-900">
        {t('mapPage.title')}
      </div>

      {/* Map Embed */}
      <div dangerouslySetInnerHTML={mapHtmlContent} className="mx-4 my-8" />

      {/* Venue Information */}
      <div className="text-center mb-6">
        <p className="text-xl font-semibold mb-1">{t('mapPage.venueName')}</p>
        <p className="text-md mb-1">{t('mapPage.floor')}</p>

        <p className="text-md mb-1">{t('mapPage.venueAddress')}</p>
        <p className="mb-3 flex items-center justify-center">
          <PhoneIcon className="h-5 w-5 text-indigo-500" />
          <a href="tel:063-833-0100" className="ml-2 text-indigo-600 hover:text-indigo-700 transition-colors duration-300">
            {t('mapPage.venuePhone')}
          </a>
        </p>
        <p className="flex items-center justify-center">
          <GlobeAltIcon className="h-5 w-5 text-indigo-500" />
          <a href="http://www.hillscourt.co.kr" target="_blank" rel="noopener noreferrer" className="ml-2 text-indigo-500 hover:text-indigo-600 transition-colors duration-300">
            {t('mapPage.venueWebsite')}
          </a>
        </p>
      </div>

      {/* Transportation Info */}
      <div className="mb-8">
        <TransportSection
          title={t('mapPage.publicTransportTitle')}
          details={t('mapPage.publicTransportDetails', { returnObjects: true })}
        />
        <TransportSection
          title={t('mapPage.busTitle')}
          details={t('mapPage.busDetails', { returnObjects: true })}
        />
        <TransportSection
          title={t('mapPage.expressBusTitle')}
          details={t('mapPage.expressBusDetails', { returnObjects: true })}
        />
      </div>

      {/* Map Service Buttons */}
      <div className="flex justify-around w-full mt-6">
        <MapButton icon={kakaoLogo} link="https://place.map.kakao.com/15194456" />
        <MapButton icon={naverLogo} link="https://naver.me/FNm7aeB7" />
      </div>
    </div>
  );
}

export default MapPage;
